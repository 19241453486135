import React, {useEffect} from "react";
import {useState} from "react";
import {Link} from "react-router-dom";
import {Button} from 'react-bootstrap';
import {ReactComponent as Icon1} from "../../assets/img/sidebar-icon-1.svg";
import {ReactComponent as Icon2} from "../../assets/img/sidebar-icon-2.svg";
import {ReactComponent as Icon3} from "../../assets/img/sidebar-icon-3.svg";
import {ReactComponent as Icon4} from "../../assets/img/sidebar-icon-4.svg";

import {ReactComponent as Icon5} from "../../assets/img/sidebar-icon-5.svg";
import {ReactComponent as Icon6} from "../../assets/img/sidebar-icon-6.svg";
import {ReactComponent as Icon7} from "../../assets/img/sidebar-icon-7.svg";
import {ReactComponent as Icon8} from "../../assets/img/sidebar-icon-8.svg";
import {ReactComponent as Icon9} from "../../assets/img/sidebar-icon-9.svg";
import {ReactComponent as Icon10} from "../../assets/img/sidebar-icon-10.svg";
import {ReactComponent as Icon11} from "../../assets/img/sidebar-icon-11.svg";
import {ReactComponent as Icon12} from "../../assets/img/sidebar-icon-12.svg";
import {ReactComponent as Icon13} from "../../assets/img/sidebar-icon-13.svg";
import {ReactComponent as Icon14} from "../../assets/img/sidebar-icon-14.svg";
import {ReactComponent as Icon15} from "../../assets/img/sidebar-icon-15.svg";
import {ReactComponent as DashFilter4} from "../../assets/img/dash-filter-4.svg";
import SideBarLogo from "../logos/SideBarLogo";
import Logout from "../../modals/Logout";
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";

import cookies from 'js-cookie';

const Sidebar = () => {

    const [showM, setShowM] = useState(false);
    const [logoImgShow, setLogoImgShow] = useState();
    const LogoImgblob = useSelector(
        (state) => state.userDataReducer.logoImg
    );
    const handleShowM = () => setShowM(true);
    const handleCallback = () => setShowM(false);

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const {t} = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguageCode.dir || 'ltr'
    }, [currentLanguageCode, t])

    useEffect(() => {
        setLogoImgShow(LogoImgblob)
    }, [LogoImgblob])

    return (
        <React.Fragment>
            <aside className="sidebar sidebar-left">
                <div className="sidebar-content">
                    <div className="aside-toolbar">
                        <ul className="site-logo">
                            <li>
                                <Link to="/home">
                                    <div className="logo-custom">
                                        <SideBarLogo/>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <nav className="main-menu">
                        <ul className="nav metismenu ">
                            <li className="d-flex align-items-center mt-2 mt-md-4">
                                <Link to="/home" className="d-flex align-items-center pl-0">
                                    <div className="icons">
                                        <Icon1/>
                                    </div>
                                    <span> {t('Main')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/home" className="d-flex align-items-center pl-0 mt-2 mt-md-3">
                                    <div className="icons">
                                        <Icon2/>
                                    </div>
                                    <span>{t('SHIPMENTS')}</span>
                                </Link>
                            </li>

                            <li className="sidebar-header d-flex align-items-center mt-3 mt-md-4 mb-2">
                                <div className="icons">
                                    <Icon7/>
                                </div>
                                <span>{t('Documents')}</span>
                            </li>
                            {
                                process.env.REACT_APP_CUSTOMER === 'Megaship' ?

                                    <></>
                                    :
                                    <li>
                                        <Link to="/invoices" className="d-flex align-items-center">
                                            <div className="icons">
                                                <Icon8/>
                                            </div>
                                            <span>{t('Invoices')}</span>
                                        </Link>
                                    </li>
                            }
                            {
                                process.env.REACT_APP_CUSTOMER === 'Megaonline' ?
                                    <></>
                                    :
                                    <li>
                                        <Link
                                            to="/quotations"
                                            className="d-flex align-items-center"
                                        >
                                            <div className="icons">
                                                <Icon9/>
                                            </div>
                                            <span>{t('Quotations')}</span>
                                        </Link>
                                    </li>
                            }
                            {/*<li>*/}
                            {/*    <Link to="/file-manager" className="d-flex align-items-center">*/}
                            {/*        <div className="icons">*/}
                            {/*            <Icon10/>*/}
                            {/*        </div>*/}
                            {/*        <span>{t('File_Manager')}</span>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li className="sidebar-header d-flex align-items-center mt-3 mt-md-4 mb-2">
                                <div className="icons">
                                    <Icon11/>
                                </div>
                                <span>{t('Account')}</span>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to="/reports" className="d-flex align-items-center">*/}
                            {/*        <div className="icons">*/}
                            {/*            <Icon12/>*/}
                            {/*        </div>*/}
                            {/*        <span>{t('Reports')}</span>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <Link to="/settings" className="d-flex align-items-center">
                                    <div className="icons">
                                        <Icon13/>
                                    </div>
                                    <span>{t('User_Profile')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/notifications" className="d-flex align-items-center">
                                    <div className="icons">
                                        <Icon14/>
                                    </div>
                                    <span>{t('Notifications')}</span>
                                </Link>
                            </li>
                            <li>
                                <Button variant="primary" className="exit-btn d-flex align-items-center"
                                        onClick={handleShowM}>
                                    <div className="icons">
                                        <Icon15/>
                                    </div>
                                    <span>{t('Logout')}</span>
                                </Button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
            <Logout showData={showM} parentCallback={handleCallback}/>
        </React.Fragment>
    );
};

export default Sidebar;
