import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as SearchIcon} from '../../assets/img/search-icon-header.svg'
import {ReactComponent as CloseIcon} from '../../assets/img/close.svg'
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SideBarLogo from "../logos/SideBarLogo";
import {Button, Form} from 'react-bootstrap';
import Logout from "../../modals/Logout";
import {useSelector} from "react-redux";
import {AutoSearch} from "./AutoSearch";
import {useTranslation} from "react-i18next";
import {dispatch} from "../../redux";
import {useFetchPendingNotificationsQuery} from "../../redux/queryAPI/customerWebsiteAPI";

const Header = () => {

    const [showM, setShowM] = useState(false);
    const [openProfileActionMenu, setOpenProfileActionMenu] = useState(false);
    const [userData, setUserData] = useState({});
    const [mobileToggle, setMobileToggle] = useState(false)
    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [searchToggle, setSearchToggle] = useState(true);
    const userDatas = useSelector((state) => state?.userDataReducer.user);
    const userProfile = useSelector((state) => state?.userDataReducer.user);

    const {data, error, isLoading} = useFetchPendingNotificationsQuery({
        pollingInterval: 3000
    });


    const handleShowM = () => setShowM(true);
    const handleCallback = () => setShowM(false);
    const handleProfileTog = () => {
        setOpenProfileActionMenu(!openProfileActionMenu)
    }
    const {t} = useTranslation()

    const handleSidebarToggleChange = () => {
        setSidebarToggle(!sidebarToggle);
        if (sidebarToggle) {
            document.querySelector('body').classList.add('aside-left-open');
        } else {
            document.querySelector('body').classList.remove('aside-left-open');
        }
    }

    const handleSearchOpen = () => {
        document.querySelector('.navbar-search').classList.add('open');
        setSearchToggle(false);
    }
    const handleSearchClose = () => {
        document.querySelector('.navbar-search').classList.remove('open');
        setSearchToggle(true);
    }

    const handleMobileToggleChange = () => {
        setMobileToggle(!mobileToggle);
        if (mobileToggle) {
            document.querySelector('body').classList.add('mobile-topbar-toggle');
        } else {
            document.querySelector('body').classList.remove('mobile-topbar-toggle');
        }
    }
    useEffect(() => {
        if (userDatas) {
            setUserData(userDatas)
        }
    }, [userDatas])

    return (
        <React.Fragment>
            <nav className="top-toolbar navbar navbar-mobile navbar-tablet">
                <ul className="navbar-nav nav-left">
                    <li className="nav-item">
                        <a onClick={handleSidebarToggleChange}>
                            <i className="icon dripicons-align-left"></i>
                        </a>
                    </li>
                </ul>
                <ul className="navbar-nav nav-left site-logo">
                    <li>
                        <Link to="/home">
                            <div className="logo-custom">
                                <SideBarLogo/>
                            </div>
                        </Link>
                    </li>
                </ul>
                <ul className="navbar-nav nav-right">
                    <li className="nav-item">
                        <a onClick={handleMobileToggleChange}>
                            <i className="icon dripicons-dots-3 rotate-90"></i>
                        </a>
                    </li>
                </ul>
            </nav>
            <nav className="top-toolbar navbar navbar-desktop flex-nowrap">
                <ul className="navbar-nav nav-right">
                    <li className="nav-item search-container mt-2">
                        {(searchToggle === true) ?
                            <a onClick={handleSearchOpen} className="search-icon">
                                <SearchIcon/>
                            </a>
                            :
                            <a onClick={handleSearchClose} className="close-icon">
                                <CloseIcon/>
                            </a>
                        }
                    </li>
                    {/* <li className="nav-item mt-2">
                        <a onClick={handleShowM}>
                            <PowerIcon />
                        </a>
                    </li> */}
                    <li className="nav-item mt-2">
                        <Link to="/notifications">
                            <div className='position-relative'>
                                {/*<Badge badgeContent={data} color="info">*/}
                                    <NotificationsIcon/>
                                {/*</Badge>*/}
                            </div>
                        </Link>
                    </li>
                    <li className="nav-item dropdown user-dropdown ml-4 mr-4">
                        <a className="nav-link nav-pill user-avatar text-left pt-3 lh-normal" data-toggle="dropdown"
                           href="#" role="button" aria-haspopup="true" aria-expanded="false" onClick={handleProfileTog}>
                            <span
                                className='font-weight-normal font-size-16'> {(userData?.firstName) ? (userData?.firstName) : (userProfile?.firstName)}{" "} {(userData?.lastName) ? (userData?.lastName) : (userProfile?.lastName)} </span>
                            <br/>
                            <small>{(userData?.organizationName) ? (userData?.organizationName) : (userProfile?.organizationName)}</small>
                        </a>
                        {openProfileActionMenu ?
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-accout d-inline-block">
                                <div className="dropdown-header pb-3">
                                    <div className="media d-user">
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0">{(userData?.firstName) ? (userData?.firstName) : (userProfile?.firstName)}{" "} {(userData?.lastName) ? (userData?.lastName) : (userProfile?.lastName)}</h5>
                                            <span>{(userData?.email) ? (userData?.email) : (userProfile?.email)}</span>
                                        </div>
                                    </div>
                                </div>
                                <Link className="dropdown-item" to="/settings"><i
                                    className="icon dripicons-user"></i> {t('My_profile')}</Link>
                                <Button className="dropdown-item text-decoration-none" variant="link"
                                        onClick={handleShowM}>
                                    <i className="icon dripicons-power mt-1 ml-0"></i> {t('Logout')}
                                </Button>
                            </div>
                            : null}
                    </li>
                </ul>
                <Form className="navbar-form navbar-search">
                    <AutoSearch/>
                </Form>
            </nav>
            <Logout showData={showM} parentCallback={handleCallback}/>
        </React.Fragment>
    );
};

export default Header;