import React, { useEffect, useRef, useState, Fragment } from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
// import Button from '@mui/material/Button';
import Add from "../assets/img/add.svg";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as ArrowDownIcon } from "../assets/img/arrow-down.svg";
import { dispatch } from "../redux";
import { useSelector } from "react-redux";
import { organizationDropdown, sendInvitation } from "../redux/action";
import {useTranslation} from "react-i18next";
export default function AddUser(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [email, setEmail] = useState("");
    const [did, setdid] = useState(props?.orgId);
    const [dValue, setdValue] = useState('');
    const [generalSearchData, setGeneralSearchData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const orgData = useSelector(
        (state) => state.userDataReducer.orgdwdnData
    );
    useEffect(() => {
        setFilteredData([]);
        setWordEntered("");
    }, [dValue])

    useEffect(() => {
        dispatch(organizationDropdown(wordEntered))
    }, [wordEntered])


    useEffect(() => {
        if (orgData) {
            setGeneralSearchData(orgData)
        }
    }, [orgData])
    const handleFilter = (event) => {
        setdValue("");

        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = generalSearchData.filter((value) => {
            return value.name.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddUser = () => {
        if (did && email) {
            dispatch(sendInvitation(did, email))
            handleClose();
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSearchClose = () => {
        setFilteredData([]);
        setWordEntered("");
    }

    const { t } = useTranslation()

    return (
        <div>
            {(props.isChild) ?
                <span aria-describedby={id} variant="contained" onClick={handleClick}>
                    <img src={Add} alt="Add" className="mr-2" /> {t('Add_user')}
                </span>

                :
                <button aria-describedby={id} variant="contained" onClick={handleClick} className="text-primary-custom btn btn-secondary">
                    {t('Add_user')}
                </button>}



            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}                
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}><div className="w-500 p-2">
                    <h4 className="mb-3">{t('Add_user')}</h4>
                    <Form.Group className="mb-3">
                        <Form.Label className="font-size-13 font-weight-bold">{t('Email')}</Form.Label>
                        <Form.Control type="text" placeholder={t('Enter_email_address')} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    {(props.showBtn !== "HIDE") ?
                        <>
                            <div className="mb-3">
                                <Form.Group className="arrow-down mb-0">
                                    <Form.Label className="font-size-13 font-weight-bold" >{t('Company')}</Form.Label>

                                    <Form.Control type="text" placeholder={t('Search_and_press_enter')} className="navbar-search" autoComplete="on" value={dValue ? dValue : wordEntered}
                                        onChange={handleFilter} />
                                    {/*<ArrowDownIcon className="arrow-down-icon" onClick={handleSearchClose} />*/}

                                </Form.Group>
                                {filteredData.length != 0 && (
                                    <div className="dataResult-org">
                                        {filteredData.slice(0, 5).map((value, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <div className="primary-stroke cus-line" onClick={() => setdid(value.id)}>
                                                        <p className="m-0" onClick={() => setdValue(value.name)}>{value.name}{value.id}</p>
                                                    </div>
                                                </Fragment>

                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </> :
                        <>
                            <div className="mb-3">
                                <Form.Group className="arrow-down mb-0">
                                    <Form.Label className="font-size-13 font-weight-bold" >{t('Company')}</Form.Label>
                                    <Form.Control type="text" className="navbar-search" autoComplete="on" value={props?.orgName} readOnly />
                                </Form.Group>
                            </div>
                        </>
                    }

                    <small className="font-size-13 font-weight-bold text-black">{t('User_will_receive_an_invitation_email')}</small>
                    <div className="mt-4">
                        <Button variant="primary" className="mr-2 btn-primary" onClick={() => handleAddUser()}>{t('Send_invitation')}</Button>
                        <Button variant="secondary" onClick={handleClose}>{t('Cancel')}</Button>
                    </div>
                </div></Typography>
            </Popover>
        </div>
    );
}
