import {compose} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import {customerWebsiteAPI} from "./queryAPI/customerWebsiteAPI";
import {setupListeners} from "@reduxjs/toolkit/query";
import userDataReducer from "./reducers/userDataReducer";
import shipmentReducer from "./reducers/shipmentReducer";
import invoiceReducer from "./reducers/invoiceReducer";
import companyReducer from "./reducers/companyReducer";
import quotationReducer from "./reducers/quotationReducer";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import shipmentFiltersReducer from "./reducers/shipmentFiltersReducer";
import invoiceFiltersReducer from "./reducers/invoiceFiltersReducer";
import quotationFiltersReducer from "./reducers/quotationFiltersReducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));
const store = configureStore({
    reducer: {
        userDataReducer,
        shipmentReducer,
        invoiceReducer,
        companyReducer,
        quotationReducer,
        [customerWebsiteAPI.reducerPath]: customerWebsiteAPI.reducer,
        shipmentFiltersReducer,
        invoiceFiltersReducer,
        quotationFiltersReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
        }).concat(customerWebsiteAPI.middleware)

});

setupListeners(store.dispatch)
export const {dispatch} = store;
export default store;

