import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-multi-date-picker";
import {
  Card,
  Breadcrumb,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Row,
  Col,
  Table,
  Accordion, Form,
} from "react-bootstrap";

import { ReactComponent as AllIcon } from "../../assets/img/all.svg";
import { ReactComponent as DashFilter1 } from "../../assets/img/dash-filter-1.svg";
import { ReactComponent as DashFilter2 } from "../../assets/img/dash-filter-2.svg";
import { ReactComponent as DashFilter3 } from "../../assets/img/dash-filter-3.svg";
import { ReactComponent as DashFilter4 } from "../../assets/img/dash-filter-4.svg";
import { ReactComponent as DashFilter6 } from "../../assets/img/dash-filter-6.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/search-icon.svg";
import { ReactComponent as Import } from "../../assets/img/import.svg";
import { ReactComponent as Export } from "../../assets/img/export.svg";

import { ReactComponent as TabShipperIcon } from "../../assets/img/tab-shipper-icon.svg";
import { ReactComponent as TabConsigneeIcon } from "../../assets/img/tab-consignee-icon.svg";
import { ReactComponent as TabDescriptionIcon } from "../../assets/img/tab-description-icon.svg";
import { ReactComponent as TabWeightIcon } from "../../assets/img/tab-weight-icon.svg";
import { ReactComponent as TabChevronDown } from "../../assets/img/tab-chevron-down.svg";
import { ReactComponent as TabAgentIcon } from "../../assets/img/tab-agent-icon.svg";
import { ReactComponent as GeneralIcon } from "../../assets/img/general-icon.svg";


import { ReactComponent as SortPrimaryIcon } from "../../assets/img/sort-primary.svg";
import { ReactComponent as SortIcon } from "../../assets/img/sort.svg";
import { withRouter } from 'react-router';
import { useHistory } from "react-router-dom";

//redux
import {useDispatch, useSelector} from "react-redux";
import {shipmentCount, shipmentList, shipmentPreviewDetails, shipmentStatus, countryFlag, fetchUserProfile} from "../../redux/action";

//components
import NotificationLA from "../../components/notifications/notificationLA";

import Loader from "react-js-loader";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import Pagination from "react-bootstrap/Pagination";
import {resetShipmentFilters, setShipmentFilters} from "../../redux/reducers/shipmentFiltersReducer";

const url = 'https://countryflagsapi.com/png/'

const Home = () => {

  const filters = useSelector((state) => state?.shipmentFiltersReducer?.homeFilters)

  const [currentPage, setCurrentPage] = useState(filters.currentpage);
  const [numberOfRecords, setNumberOfRecords] = useState(filters.numberofrecords);
  const [sortType, setSortType] = useState(false);
  const [countData, setCountData] = useState(null);
  const [listData, setListData] = useState([]);
  const [shipDtl, setShipDtl] = useState({});
  const [status, setStatus] = useState({});
  const [filterTransport, setFilterTransport] = useState(filters.transport);
  const [filterFlow, setFilterFlow] = useState(filters.flow);
  const [fromRecord, setFromRecord] = useState(0);
  const [searchText, setSearchText] = useState(filters.text);
  const [statusFilter, setStatusFilter] = useState(filters.status);
  const [flagCountry, setFlagCountry] = useState([]);

  const sessionsPerPage = numberOfRecords;
  const history = useHistory()
  const dispatch = useDispatch()

  const ShipmentCount = useSelector((state) => state?.shipmentReducer?.shipCount);
  const ShipmentAllListData = useSelector((state) => state?.shipmentReducer?.allShipList);
  const ShipmentStatusData = useSelector((state) => state?.shipmentReducer?.statusList);
  const ShipmentDetails = useSelector((state) => state?.shipmentReducer?.shipPreviewDtl);
  const isLoading = useSelector((state) => state?.shipmentReducer?.isLoading);
  const countryFlagData = useSelector((state) => state?.shipmentReducer?.countryData)

  const pagesCount = Math.ceil(countData / numberOfRecords);
  const isPaginationShown = true;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;
  let isPageNumberOutOfRange;

  const changePage = number => {
    if (currentPage === number) return;
    setCurrentPage(number);
    dispatch(setShipmentFilters({ ...filters, fromrecord: (currentPage - 1) * sessionsPerPage}))
  };

  const onPageNumberClick = pageNumber => {
    changePage(pageNumber);
    dispatch(setShipmentFilters({ ...filters, fromrecord: (pageNumber - 1) * sessionsPerPage}))
  };

  const onPreviousPageClick = () => {
    changePage(currentPage => currentPage - 1);
    dispatch(setShipmentFilters({ ...filters, fromrecord: (currentPage - 2) * sessionsPerPage}))
  };

  const onNextPageClick = () => {
    changePage(currentPage => currentPage + 1);
    dispatch(setShipmentFilters({ ...filters, fromrecord: currentPage * sessionsPerPage}))
  };

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
        Math.abs(pageNumber - currentPage) <= 2;

    if (
        isPageNumberFirst ||
        isPageNumberLast ||
        isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
          <Pagination.Item
              key={pageNumber}
              onClick={() => onPageNumberClick(pageNumber)}
              active={pageNumber === currentPage}
          >
            {pageNumber}
          </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  useEffect(()=>{
    dispatch(fetchUserProfile());
  },[]);

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const { t } = useTranslation()

  // const [dates, setDates] = useState([])
  const [fromDate, setFromDate] = useState(filters.fromdate);
  const [toDate, setToDate] = useState(filters.todate);

  useEffect(() => {
    if (fromDate) {
      let date = JSON.stringify(fromDate)
      dispatch(setShipmentFilters({ ...filters, fromdate: date, fromrecord: 0, currentpage: 1 }))
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate) {
      let date = JSON.stringify(toDate)
      dispatch(setShipmentFilters({ ...filters, todate: date, fromrecord: 0, currentpage: 1 }))
    }
  }, [toDate]);

  useEffect(() => {

    dispatch(shipmentCount(filters));
    dispatch(shipmentList(filters));
    // console.log('HEM ARRIBAT A SHIPMENT STATUS')
    dispatch(shipmentStatus(filters));
  }, [filters]);

  useEffect(() => {
    if (filterTransport) {
      dispatch(setShipmentFilters({ ...filters, transport: filterTransport, fromrecord: 0, currentpage: 1 }))
    }
  }, [filterTransport]);

  useEffect(() => {
    if (filterFlow) {
      dispatch(setShipmentFilters({ ...filters, flow: filterFlow, fromrecord: 0, currentpage: 1 }))
    }
  }, [filterFlow])

  useEffect(() => {
    if (statusFilter) {
      dispatch(setShipmentFilters({ ...filters, status: statusFilter, fromrecord: 0, currentpage: 1 }))
    }
  }, [statusFilter])

  useEffect(() => {
    if (currentPage) {
      dispatch(setShipmentFilters({ ...filters, currentpage: currentPage }))
    }
  }, [currentPage])

  useEffect(() => {
    dispatch((countryFlag()));
  }, [])

  useEffect(() => {
      setCountData(ShipmentCount);
  }, [ShipmentCount]);

  useEffect(() => {
    if (ShipmentAllListData) {
      setListData(ShipmentAllListData);
    }
  }, [ShipmentAllListData]);

  useEffect(() => {
    if (countryFlagData) {
      setFlagCountry(countryFlagData);
    }
  }, [countryFlagData]);

  useEffect(() => {
    if (ShipmentStatusData) {
      setStatus(ShipmentStatusData)
    }
  }, [ShipmentStatusData])

  useEffect(() => {
    if (ShipmentDetails) {
      setShipDtl(ShipmentDetails)
    }
  }, [ShipmentDetails])

  useEffect(() => {
    document.body.dir = currentLanguageCode.dir || 'ltr'
  }, [currentLanguageCode, t])

  const handleDetails = (id) => {
    dispatch(shipmentPreviewDetails(id));
  }
  const handleSort = (sortName) => {
    setSortType(!sortType)

    if (sortType === false) {

      listData.sort(function (a, b) {
        if (a[sortName] < b[sortName]) return 1;
        if (a[sortName] > b[sortName]) return -1;
        return 0;
      });
      return listData;
    } else {

      listData.sort(function (a, b) {
        if (a[sortName] < b[sortName]) return -1;
        if (a[sortName] > b[sortName]) return 1;
        return 0;
      });
      return listData;
    }
  }


  const handlef1 = (e) => {
    console.log('E', e);
    setFilterTransport(e);
  }
  const handlef2 = (e) => {
    setFilterFlow(e);
  }
  const handleRefresh = () => {
    dispatch(resetShipmentFilters());
    setFilterTransport('');
    setFilterFlow('');
    setSearchText('');
    setStatusFilter('');
    setFromDate('');
    setToDate('');
    setCurrentPage(1);
    setFromRecord(0);
  }

  const handleStatus = (e) => {
    setStatusFilter(e)
  }

  //Date Handlers

  const startRefTo = useRef();
  const startRefFrom = useRef();

  const handleKeyDownTo = () => {
    if (startRefFrom?.current?.getElementsByClassName("rmdp-calendar")?.length !== 0) {
      const valRef = startRefFrom?.current?.getElementsByClassName("rmdp-calendar");
      valRef[0].classList.add("HIDE");
    }
  };

  const handleKey = () => {
    if (startRefTo?.current?.getElementsByClassName("rmdp-calendar")?.length !== 0) {
      const valRefTo = startRefTo?.current?.getElementsByClassName("rmdp-calendar");
      valRefTo[0].classList.add("HIDE");
    }

  }
  const handleOpenFrom = () => {
    if (startRefFrom?.current?.getElementsByClassName("HIDE")) {
      const valRefHide = startRefFrom?.current?.getElementsByClassName("rmdp-calendar");
      valRefHide[0].classList.remove("HIDE");
    }
  }

  const handleOpenTo = () => {
    if (startRefTo?.current?.getElementsByClassName("HIDE")) {
      const valRefHideTo = startRefTo?.current?.getElementsByClassName("rmdp-calendar");
      valRefHideTo[0].classList.remove("HIDE");
    }
  }

  //for search Box
  const handleSearch = (text) => {
    setSearchText(text);
    dispatch(setShipmentFilters({ ...filters, text: text, fromrecord: 0, currentpage: 1 }))
  }


  return (
    <React.Fragment>
      <div className="content">
        <Breadcrumb>
          <Breadcrumb.Item active>{t('Home')}</Breadcrumb.Item>
        </Breadcrumb>
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>{t('Your_Shipments_in_One_View')}</h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Row className="form-row">
            <Col xl={9}>
              <Card>
                <Card.Body className="d-flex flex-wrap justify-content-between">
                  <ToggleButtonGroup
                    type="radio"
                    name="options"
                    className="dashboard-filter flex-wrap"
                    value={filterTransport ? filterTransport : "ALL"}
                    defaultValue="ALL"
                    onChange={(e) => handlef1(e)}
                  >
                    <ToggleButton id="tbg-radio-1" variant="" value="ALL">
                      <div className="icon">
                        <AllIcon />
                      </div>
                      {t('All')}
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-2" variant="" value="SEA">
                      <div className="icon">
                        <DashFilter1 />
                      </div>
                      {t('Seafreight')}
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-3" variant="" value="AIR">
                      <div className="icon">
                        <DashFilter2 />
                      </div>
                      {t('Airfreight')}
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-4" variant="" value="LAND">
                      <div className="icon">
                        <DashFilter3 />
                      </div>
                      {t('Land_transport')}
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-5" variant="" value="COUR">
                      <div className="icon">
                        <DashFilter4 />
                      </div>
                      {t('Courier')}
                    </ToggleButton>
                    {/*<ToggleButton id="tbg-radio-6" variant="" value="HOTSHIP">*/}
                    {/*  <div className="icon">*/}
                    {/*    <DashFilter5 />*/}
                    {/*  </div>*/}
                    {/*  {t('Hot_shipments')}*/}
                    {/*</ToggleButton>*/}
                  </ToggleButtonGroup>
                  <ToggleButtonGroup
                    type="radio"
                    name="options2"
                    value={filterFlow ? filterFlow : "ALL"}
                    defaultValue="ALL"
                    className="dashboard-filter flex-wrap right-side"
                    onChange={(e) => handlef2(e)}
                  >
                    <ToggleButton id="tbg-radio-01" variant="" value="ALL">
                      <div className="icon">
                        <AllIcon />
                      </div>
                      {t('All')}
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-02" variant="" value="IMPORT">
                      <div className="icon">
                        <Import />
                      </div>
                      {t('Import')}
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-03" variant="" value="EXPORT">
                      <div className="icon">
                        <Export />
                      </div>
                      {t('Export')}
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-04" variant="" value="CROSS-TRADE">
                      <div className="icon">
                        <DashFilter6 />
                      </div>
                      {t('X_Trade')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <ToggleButtonGroup
                    type="radio"
                    name="options"
                    defaultValue={statusFilter ? statusFilter : ""}
                    value={statusFilter}
                    className="dashboard-filter flex-wrap form-row small-chips d-flex flex-fill mb-2"
                    onChange={(e) => handleStatus(e)}
                  >
                    <ToggleButton id="tbg-radio-1" variant="" value="PREBOOKING" className="flex-fill">
                      <div className="card-body d-flex align-items-center flex-row px-2 py-1 justify-content-md-center dash-box shadow-none m-0" >
                        <h2 className="card-title font-size-40 mr-2 mb-0">
                          {status.preBooking}
                        </h2>
                        <h6 className="mb-0">
                          {t('Booking')}
                          <br />
                          {t('Pending')}
                        </h6>
                      </div>
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-1" variant="" value="BOOKED" className="flex-fill">
                      <div className="card-body d-flex align-items-center flex-row px-2 py-1 justify-content-md-center dash-box shadow-none m-0" >
                        <h2 className="card-title font-size-40 mr-2 mb-0">
                          {status.booked}
                        </h2>
                        <h6 className="mb-0">
                          {t('Booked')}
                        </h6>
                      </div>
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-2" variant="" value="LOADEDONBOARD" className="flex-fill">
                      <div className="card-body d-flex align-items-center flex-row px-2 py-1 justify-content-md-center dash-box shadow-none m-0">
                        <h2 className="card-title font-size-40 mr-2 mb-0">
                          {status.loadedOnBoard}
                        </h2>
                        <h6 className="mb-0">
                          {'Loaded'}
                          <br />
                          {'on board'}
                        </h6>
                      </div>
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-3" variant="" value="PENDINGDELIVERY" className="flex-fill">
                      <div className="card-body d-flex align-items-center flex-row px-2 py-1 justify-content-md-center dash-box shadow-none m-0">
                        <h2 className="card-title font-size-40 mr-2 mb-0">
                          {status.pendingDelivery}
                        </h2>
                        <h6 className="mb-0">
                          {t('Pending')}
                          <br />
                          {t('Delivery')}
                        </h6>
                      </div>
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-4" variant="" value="DELIVERED" className="flex-fill">
                      <div className="card-body d-flex align-items-center flex-row px-2 py-1 justify-content-md-center dash-box shadow-none m-0">
                        <h2 className="card-title font-size-40 mr-2 mb-0">
                          {status.delivered}
                        </h2>
                        <h6 className="mb-0">
                          {t('Delivered')}
                        </h6>
                      </div>
                    </ToggleButton>

                  </ToggleButtonGroup>
                  <Card className="mb-0">
                    <div className="card-body">
                      <Row className="mb-3 form-row align-items-center">
                        <Col lg={8}>
                          <div className="table-top-select">
                            <div className="arrow-down d-inline-block mr-2 mr-lg-4">
                              <div className="d-flex flex-wrap align-items-center">
                                <div className="date-picker mb-2 mb-sm-0 mr-2 mr-lg-3" onClick={handleOpenFrom}>
                                  <span className="icon">
                                    <i className="zmdi zmdi-calendar"></i>
                                  </span>
                                  <DatePicker value={fromDate} onChange={setFromDate} inputClass="form-control" placeholder={t('From')} format="DD-MM-YYYY" ref={startRefFrom} onOpen={(e) => handleKeyDownTo(e)}/>
                                </div>
                                <div className="date-picker mb-2 mb-sm-0" onClick={handleOpenTo}>
                                  <span className="icon">
                                    <i className="zmdi zmdi-calendar"></i>
                                  </span>
                                  <DatePicker value={toDate} onChange={setToDate} ref={startRefTo} onOpen={(e) => handleKeyDownTo(e)} inputClass="form-control" placeholder={t('To')} format="DD-MM-YYYY" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} className="d-flex justify-content-lg-end mt-2 mt-lg-0">
                          <div className="table-top-search d-flex mr-3" >
                            <SearchIcon className="icon" />
                            <Form.Control type="text" placeholder={t('Search')} value={searchText} onChange={(e) => handleSearch(e.target.value)}
                              onFocus={handleKey}
                            />
                          </div>
                          <span className="refresh-icon" onClick={handleRefresh}>
                            <i className="zmdi zmdi-refresh font-size-18 align-middle"></i>
                          </span>
                        </Col>
                      </Row>
                      <div className="accordion-table">
                        <Accordion defaultActiveKey={["0"]} alwaysopen="true">
                          <Table size="sm" responsive borderless hover>
                            <thead>
                              <tr>
                                <th className="w-100"></th>
                                <th className="text-primary-custom"
                                  onClick={() => handleSort("shipmentName")}
                                >
                                  {t('Shipment')}{" "}
                                  <SortPrimaryIcon className="sort-primary-icon align-baseline" />
                                </th>
                                <th onClick={() => handleSort("originName")}>
                                  {t('Origin')} <SortIcon className="align-baseline" />
                                </th>
                                <th onClick={() => handleSort("destinationName")}>
                                  {t('Destination')}{" "}
                                  <SortIcon className="align-baseline" />
                                </th>
                                <th onClick={() => handleSort("trafficFlow")}>
                                  {t('Flow')} <SortIcon className="align-baseline" />
                                </th>
                                <th onClick={() => handleSort("etd")}>
                                  {t('ETD')}  <SortIcon className="align-baseline" />
                                </th>
                                <th onClick={() => handleSort("eta")}>
                                  {t('ETA')}  <SortIcon className="align-baseline" />
                                </th>
                                <th onClick={() => handleSort("customerReference")}>
                                  {t('Reference')}  <SortIcon className="align-baseline" />
                                </th>
                                <th onClick={() => handleSort("status")}>
                                  {t('Status')}  <SortIcon className="align-baseline" />
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>

                              {listData &&
                                  listData?.map((item, index) => (
                                  <React.Fragment key={item?.shipmentId}>
                                    <tr key={index} >
                                      <td onClick={() => history.push({ pathname: "/seafright/" + item?.shipmentId })}
                                      // changePage(item?.transportMode, item?.shipmentId)}
                                      >
                                        <div className="primary-stroke">
                                          {(item?.transportMode === "SEA") ? <DashFilter1 className="w-60" /> : (item?.transportMode === "AIR") ? <DashFilter2 className="w-60" /> : (item?.transportMode === "LAND") ? <DashFilter3 className="w-60" /> : (item?.transportMode === "COUR") ? <DashFilter4 className="w-60" /> : <DashFilter6 className="w-60" />}

                                        </div>
                                      </td>
                                      <td >{item?.shipmentName}</td>
                                      <td>{item?.originName}</td>
                                      <td>{item?.destinationName}</td>
                                      <td>{item?.trafficFlow}</td>
                                      <td>{item?.etd}</td>
                                      <td>{item?.eta}</td>
                                      <td>{item?.customerReference}</td>
                                      <td>
                                        {t(item?.status)}
                                      </td>
                                      <td className="text-right " onClick={() => handleDetails(item?.shipmentId)}>
                                        <Accordion.Toggle
                                          as={Button}
                                          eventKey={index + 1}
                                          className="px-1"
                                        >
                                          <TabChevronDown />
                                        </Accordion.Toggle>
                                      </td>
                                    </tr>
                                    <tr className="accordian-body" onClick={() => history.push({ pathname: "/seafright/" + item?.shipmentId })} >
                                      <td colSpan={10} className="p-0">
                                        {isLoading ? <Loader type="spinner-cub" bgColor={"#4068ef"} title={"Loading..."} color={'#4068ef'} size={50} /> :
                                          <Accordion.Collapse eventKey={index + 1}>
                                            <Card className="m-0 shadow-none">
                                              <Card.Header className="bg-primary py-2 p-l-20 pr-2 d-flex align-items-center h-auto">
                                                <Row className="form-row align-items-center flex-fill">
                                                  <Col xs={6}>
                                                    <div className="d-flex align-items-center">
                                                      {(shipDtl?.transportMode === "SEA") ? <DashFilter1 className="accordion-icon mr-3" /> : (shipDtl?.transportMode === "AIR") ? <DashFilter2 className="accordion-icon mr-3" /> : (shipDtl?.transportMode === "LAND") ? <DashFilter3 className="accordion-icon mr-3" /> : (shipDtl?.transportMode === "COUR") ? <DashFilter4 className="accordion-icon mr-3" /> : <DashFilter6 className="accordion-icon mr-3" />}
                                                      <h6 className="text-white m-0">
                                                        {" "}
                                                        {t('Shipment')}
                                                        {" "}
                                                        <span className="text-muted ml-3">
                                                          {" "}
                                                          {shipDtl?.shipmentName}{" "}
                                                        </span>{" "}
                                                      </h6>
                                                    </div>
                                                  </Col>
                                                  <Col xs={4}>
                                                    <h6 className="text-white m-0">
                                                      {" "}
                                                      {shipDtl?.transportMode === 'SEA' ? 'BL' :
                                                          shipDtl?.transportMode === 'AIR' ? 'AWB' :
                                                              shipDtl?.transportMode === 'LAND' ? 'CMR' :
                                                                  shipDtl?.transportMode === 'COUR' ? 'ISN' : ''}
                                                      {" "}
                                                      <span className="text-muted ml-3">
                                                        {" "}
                                                        {shipDtl?.blNumber}{" "}
                                                      </span>{" "}
                                                    </h6>
                                                  </Col>
                                                  <Col xs={2}>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                      <h6 className="text-white m-0">
                                                        {" "}
                                                        {t(shipDtl?.status)}{" "}
                                                      </h6>
                                                      <Accordion.Toggle
                                                        as={Button}
                                                        eventKey={index + 1}
                                                        className="p-0 ml-2 h-auto"
                                                      >
                                                        <TabChevronDown />
                                                      </Accordion.Toggle>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Card.Header>
                                              <Card.Body>
                                                <p className="text-primary-custom mb-3 h6 text-right">
                                                  {/* Export */}
                                                  {shipDtl?.trafficFlow} {shipDtl?.trafficChargeType}
                                                </p>
                                                <div className="d-flex flex-md-row align-items-center">
                                                  <div className="text-center pl-2 mt-n-2 mr-4">
                                                    {
                                                      // (shipDtl?.goodsCategory === "isDangerous") ? <TabShipmentErrorIcon className="shipment-icon" height="50" /> : (shipDtl?.goodsCategory === "isGDP") ? <GDPIcon className="shipment-icon" height="50" /> : (shipDtl?.goodsCategory === "isTempeatureControled") ? <TempControlledIcon className="shipment-icon" height="50" /> : (shipDtl?.goodsCategory === "Genera") ?
                                                      <GeneralIcon className="shipment-icon" height="50" />
                                                      // : <TabShipmentErrorIcon className="shipment-icon" height="50" />
                                                    }
                                                  </div>
                                                  <div className="flex-fill">
                                                    <Row className="form-row">
                                                      <Col lg={7}>
                                                        <div className="shipment-time-line">
                                                          <div className="time-line mb-3">
                                                            <div className="mb-3">
                                                              <p className="text-primary-custom dote mb-2 h6">
                                                                {shipDtl?.etd}{" "}{shipDtl?.origin}

                                                              </p>
                                                              <small className="font-weight-bold">
                                                                {shipDtl?.tansitTime}
                                                              </small>
                                                            </div>
                                                            <p className="text-primary-custom dote m-0 h6">
                                                              {shipDtl?.eta}{" "}{shipDtl?.destination}
                                                            </p>
                                                          </div>
                                                          <small className="font-weight-bold pl-2">
                                                          {`${(shipDtl?.shippingline !== null)?shipDtl?.shippingline : " "}${(shipDtl?.airline !== null)?shipDtl?.airline : " " }${(shipDtl?.voyageNumber !== null)?"  " + shipDtl?.voyageNumber : ""}`}
                                                          </small>
                                                        </div>
                                                      </Col>
                                                      <Col lg={5} className="mt-3 mt-lg-0">
                                                        <Row xs={2} className="m-b-10">
                                                          <Col className="col-lg-6 text-primary-custom font-weight-700">
                                                            <div className="d-flex align-items-center justify-content-end">
                                                              <div className="w-40 text-right mr-2">
                                                                <TabShipperIcon className="shipper-icon w-40" />
                                                              </div>{" "}
                                                              Shipper
                                                            </div>
                                                          </Col>
                                                          <Col className="col-lg-6">
                                                            {shipDtl?.shipperName}
                                                          </Col>
                                                        </Row>
                                                        <Row xs={2} className="m-b-10">
                                                          <Col className="col-lg-6 text-primary-custom font-weight-700">
                                                            <div className="d-flex align-items-center justify-content-end">
                                                              <div className="w-40 text-right mr-2">
                                                                <TabConsigneeIcon className="consignee-icon" />
                                                              </div>{" "}
                                                              Consignee
                                                            </div>
                                                          </Col>
                                                          <Col className="col-lg-6">
                                                            {shipDtl?.consigneeName}
                                                          </Col>
                                                        </Row>
                                                        <Row xs={2} className="m-b-10">
                                                          <Col className="col-lg-6 text-primary-custom font-weight-700">
                                                            <div className="d-flex align-items-center justify-content-end">
                                                              <div className="w-40 text-right mr-2">
                                                                <TabAgentIcon className="agent-icon" />
                                                              </div>{" "}
                                                              Agent
                                                            </div>
                                                          </Col>
                                                          <Col className="col-lg-6">
                                                            {shipDtl?.agentName}
                                                          </Col>
                                                        </Row>
                                                        <Row xs={2} className="m-b-10">
                                                          <Col className="col-lg-6 text-primary-custom font-weight-700">
                                                            <div className="d-flex align-items-center justify-content-end">
                                                              <div className="w-40 text-right mr-2">
                                                                <TabDescriptionIcon className="description-icon" />
                                                              </div>{" "}
                                                              Description
                                                            </div>
                                                          </Col>
                                                          <Col className="col-lg-6">
                                                            {shipDtl?.goodsDescription}
                                                          </Col>
                                                        </Row>
                                                        <Row xs={2} className="m-b-10">
                                                          <Col className="col-lg-6 text-primary-custom font-weight-700">
                                                            <div className="d-flex align-items-center justify-content-end">
                                                              <div className="w-40 text-right mr-2">
                                                                <TabWeightIcon className="weight-icon" />
                                                              </div>{" "}
                                                              Weight
                                                            </div>
                                                          </Col>
                                                          <Col className="col-lg-6">
                                                            {shipDtl?.grossWeight}
                                                          </Col>
                                                        </Row>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </Card.Body>
                                            </Card>
                                          </Accordion.Collapse>
                                        }
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}

                            </tbody>
                          </Table>
                        </Accordion>
                      </div>
                      {/* } */}
                    </div>
                  </Card>

                  <>
                    {isPaginationShown && ShipmentCount > 0 && (
                        <Pagination size="sm" className='pt-2 mt-4 justify-content-end'>
                          <Pagination.Prev
                              onClick={onPreviousPageClick}
                              disabled={isCurrentPageFirst}
                          />
                          {pageNumbers}
                          <Pagination.Next
                              onClick={onNextPageClick}
                              disabled={isCurrentPageLast}
                          />
                        </Pagination>
                    )}
                  </>
                </Card.Body>
              </Card>
              {/*<Card>*/}
              {/*  <Card.Header className="border-0">*/}
              {/*    <h2 className="mb-0">*/}
              {/*      {t('Global Situation')}*/}
              {/*    </h2>*/}
              {/*  </Card.Header>*/}
              {/*  <Card.Body className="pt-0">*/}
              {/*    <Row className="form-row">*/}
              {/*      <Col md={6} className="mb-4 mb-md-0">*/}
              {/*        <div className="d-flex flex-column min-h-100p">*/}
              {/*          <Card.Header className="border-0 text-dark px-0 pt-0 font-size-18">*/}
              {/*            Principales Shippers/Consignees*/}
              {/*          </Card.Header>*/}
              {/*          <Card className="m-0 flex-fill">*/}
              {/*            <Card.Body>*/}
              {/*              <div className="accordion-table">*/}
              {/*                <Table*/}
              {/*                  size="sm"*/}
              {/*                  responsive*/}
              {/*                  borderless*/}
              {/*                >*/}
              {/*                  <tbody>*/}
              {/*                    {flagCountry && flagCountry.map((data, i) => (*/}
              {/*                      <tr key={i}>*/}
              {/*                        <td>{data.name}</td>*/}
              {/*                        <td className="text-right">*/}
              {/*                          {data.country}{" "}*/}
              {/*                        </td>*/}
              {/*                        <td className="w-50">*/}
              {/*                          <div key={i}>*/}
              {/*                            <img src={`${url + data.country}`} alt="flag" className="w-25 h-15 ml-1" />*/}
              {/*                          </div>*/}
              {/*                        </td>*/}
              {/*                      </tr>*/}
              {/*                    ))}*/}
              {/*                  </tbody>*/}
              {/*                </Table>*/}
              {/*              </div>*/}
              {/*            </Card.Body>*/}
              {/*          </Card>*/}
              {/*        </div>*/}
              {/*      </Col>*/}
              {/*      <Col md={6}>*/}
              {/*        <div className="d-flex flex-column min-h-100p">*/}
              {/*          <Card.Header className="border-0 text-dark px-0 pt-0 font-size-18">*/}
              {/*            Principales destinos/origenes*/}
              {/*          </Card.Header>*/}
              {/*          <Card className="m-0 flex-fill">*/}
              {/*            <Card.Body>*/}
              {/*              <Worldmap height={500} page="home" />*/}
              {/*              /!* <ReactTooltip>{content}</ReactTooltip> *!/*/}
              {/*            </Card.Body>*/}
              {/*          </Card>*/}
              {/*        </div>*/}
              {/*      </Col>*/}

              {/*    </Row>*/}
              {/*  </Card.Body>*/}
              {/*</Card>*/}
            </Col>
            <NotificationLA />
          </Row>
        </section>
      </div>
      {/* <UploadNotifications showData={showM} parentCallback={handleCallback} /> */}
    </React.Fragment>
  );
};

export default withRouter(Home);
