import {apiCall, METHOD} from "../../service/baseApiCall";
import {
    QUOTATIONLIST,
    QUOTATIONFILTER,
    QUOTATIONCOUNT,
    QUOTATIONSTATUS
} from "../constants/action-types";

export const quotationCount = (val) => (dispatch) => {
    dispatch(quotationCountInit(val));
};

export const quotationCountInit = (val) => (dispatch) => {
    var values= Object.entries(val);
    dispatch({
        type: QUOTATIONCOUNT.QUOTATIONCOUNT_INITLIZATION
    });
    let url = new URL(process.env.REACT_APP_BASE_API_CALL + 'quotation/count');
    if(values){
        values.map((i)=>{
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }
    apiCall(
        url,
        {
        },
        (res) => dispatch(quotationCountSuccess(res)),
        () =>  dispatch(quotationCountError()) ,
        METHOD.GET,
        {}
    );
};

export const quotationCountSuccess = (res) => (dispatch) => {
    dispatch({
        type: QUOTATIONCOUNT.QUOTATIONCOUNT_SUCCESS ,
        payload: res?.data
    });
};

export const quotationCountError = () => (dispatch) => {
    dispatch({
        type: QUOTATIONCOUNT.QUOTATIONCOUNT_ERORR
    });
};

export const quotationList = (val) => (dispatch) => {
    dispatch(quotationListInit(val));
};

export const quotationListInit = (val) => (dispatch) => {
    var values = Object.entries(val);
    dispatch({
        type: QUOTATIONLIST.QUOTATIONLIST_INITLIZATION
    });
    let url = new URL(process.env.REACT_APP_BASE_API_CALL + 'quotation/list');

    if (values) {
        values.map((i) => {
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }

    apiCall(
        url,
        {},
        (res) => dispatch(quotationListSuccess(res)),
        () => dispatch(quotationListError()),
        METHOD.GET,
        {}
    );
};

export const quotationListSuccess = (res) => (dispatch) => {
    dispatch({
        type: QUOTATIONLIST.QUOTATIONLIST_SUCCESS,
        payload: res?.data
    });
};

export const quotationListError = () => (dispatch) => {
    dispatch({
        type: QUOTATIONLIST.QUOTATIONLIST_ERROR
    });
};

export const quotationStatus = (val) => (dispatch) => {
    dispatch(quotationStatusInit(val));
};

export const quotationStatusInit = (val) => (dispatch) => {
    dispatch({
        type: QUOTATIONSTATUS.QUOTATIONSTATUS_INITIALIZATION
    });

    let url = new URL(process.env.REACT_APP_BASE_API_CALL + `quotation/status`);

    var values= Object.entries(val);
    if(values){
        values.map((i)=>{
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }
    apiCall(
        url
        ,
        {

        },
        (res) => dispatch(quotationStatusSuccess(res)),
        () =>  dispatch(quotationStatusError()),
        METHOD.GET,
        {}
    );
};

export const quotationStatusSuccess = (res) => (dispatch) => {
    dispatch({
        type: QUOTATIONSTATUS.QUOTATIONSTATUS_SUCCESS,
        payload: res?.data
    });
};

export const quotationStatusError = () => (dispatch) => {
    dispatch({
        type: QUOTATIONSTATUS.QUOTATIONSTATUS_ERORR
    });
};

//quotation filter type

export const quotationFilter = () => (dispatch) => {
    dispatch(quotationFilterInit());
};

export const quotationFilterInit = () => (dispatch) => {
    dispatch({
        type: QUOTATIONFILTER.QUOTATIONFILTER_INITIALIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + "quotation/status",
        {},
        (res) => dispatch(quotationFilterSuccess(res)),
        () => dispatch(quotationFilterError()),
        METHOD.GET,
        {}
    );
};

export const quotationFilterSuccess = (res) => (dispatch) => {
    dispatch({
        type: QUOTATIONFILTER.QUOTATIONFILTER_SUCCESS,
        payload: res?.data
    });
};

export const quotationFilterError = () => (dispatch) => {
    dispatch({
        type: QUOTATIONFILTER.QUOTATIONFILTER_ERROR
    });
};