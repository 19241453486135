import React, {useState} from "react";
import {useFormik} from "formik";
import {Button, Modal, Spinner} from 'react-bootstrap';
import MainLogo from "../../components/logos/MainLogo";
import {Link} from 'react-router-dom';

import {useTranslation} from "react-i18next";
import {useRecoveryPasswordMutation} from "../../redux/queryAPI/customerWebsiteAPI";
import * as Yup from "yup";
import {toast} from "react-toastify";

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const ForgotPassword = () => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Afegim un estat per gestionar l'indicador de carrega
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [recoverPassword, result] = useRecoveryPasswordMutation();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit: async (values) => {
            setIsLoading(true); // Activa l'indicador de carrega
            try {
                await recoverPassword(values.email);

                console.log('RESULT', result)
                // Aquí pots gestionar la resposta de la mutació, com mostrar el modal o errors si cal
                if(result.isSuccess){
                    handleShow();
                }
                if(result.isError){
                    toast.error(result.error.data)
                }


            } catch (error) {
                // Aquí pots gestionar els errors
                console.error(error);
            }
            setIsLoading(false); // Desactiva l'indicador de carrega quan s'ha completat
        },
    });

    const { errors, touched } = formik;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="main-container d-flex align-items-center justify-content-center">
                <div className="login-box">
                    <div className="login-logo">
                        <MainLogo/>
                    </div>
                    <div className="login-box-body">
                        <h1 className="text-center mb-3 font-weight-600">{t('Forgot_Password')}</h1>
                        <p className="text-center mb-4">{t('Enter_your_registered_email')}</p>
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t('Email')}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.email}
                                       onChange={formik.handleChange}
                                       name="email" id="email"/>
                                {errors.email && touched.email && (
                                    <small className="text-danger">{errors.email}</small>
                                )}
                            </div>
                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                    onClick={() => {
                                        formik.handleSubmit();
                                        //history.push({pathname: "/"})
                                    }}>
                                {isLoading ? (
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                ) : (
                                    t('Submit')
                                )}
                            </button>
                        </form>
                    </div>
                    <div className="login-box-footer text-center">
                        {t('Remember_Password')} <Link className="text-primary-custom" to="/"> {t('SignIn')}</Link>
                    </div>
                </div>
            </div>
            <Modal centered show={show} onHide={handleClose} className="warning-modal" animation={false}>
                <Modal.Header className="align-items-center">
                    <h3 className="modal-title">Password Recovery?</h3>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-default font-size-12 m-0">An email has been sent to your registered email ID
                        containing the password reset link along with a list of instructions. Please follow the steps
                        mentioned in the email to reset your password.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button variant="primary" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>

    )
}

export default ForgotPassword;
